//import chroma from "chroma-js";
import { LayerSpecification, StyleSpecification } from "maplibre-gl";
import { labels_layers, nolabels_layers } from "protomaps-themes-base/src/base_layers";
import { DARK, Theme } from "protomaps-themes-base/src/themes";

export default ({
  url,
  maxzoom,
  theme,
  sprite,
  buildings3D,
  buildingsDetour,
  allPois,
}: {
  url: string;
  maxzoom: number;
  theme: Theme;
  sprite?: string;
  buildings3D?: boolean;
  buildingsDetour?: boolean;
  allPois?: boolean;
}): StyleSpecification => {
  const source = "protomaps";
  const customTheme = { ...(theme || DARK) };

  let layers: LayerSpecification[] = nolabels_layers(source, customTheme);
  if (buildingsDetour) {
    // rajout d'un détour autour des buildings
    /*layers.forEach(layer => {
              if (layer.id == 'buildings') {
                  layer.paint = {
                      ...layer.paint,
                      "fill-opacity": 1
                  }
              }
          })*/
    layers.push({
      id: "buildings-detour",
      type: "line",
      source: source,
      "source-layer": "buildings",
      paint: {
        "line-color": customTheme.background,
        "line-width": 1,
      },
    });
  }
  if (buildings3D) {
    layers.push({
      id: "buildings-3d",
      source: source,
      "source-layer": "buildings",
      type: "fill-extrusion",
      minzoom: 15,
      paint: {
        "fill-extrusion-color": [
          "interpolate",
          ["linear"],
          ["coalesce", ["get", "height"], 0],
          0,
          customTheme.buildings,
          200,
          customTheme.buildings, // chroma(customTheme.buildings).saturate(0.5).hex(),
          400,
          customTheme.buildings, //chroma(customTheme.buildings).saturate(1).hex(),
        ],
        "fill-extrusion-height": ["interpolate", ["linear"], ["zoom"], 15, 0, 16, ["get", "height"]],
        "fill-extrusion-base": ["case", [">=", ["get", "zoom"], 16], ["get", "min_height"], 0],
        "fill-extrusion-opacity": 0.6,
      },
    });
  }
  layers = layers.concat(labels_layers(source, customTheme));

  if (allPois) {
    layers.push({
      id: "all-pois",
      type: "symbol",
      source: source,
      minzoom: 15,
      "source-layer": "pois",
      filter: ["any", [">=", ["get", "pmap:min_zoom"], 13]],
      layout: {
        "symbol-sort-key": ["get", "pmap:min_zoom"],
        "text-font": ["Noto Sans Regular"],
        "text-field": ["get", "name"],
        "text-size": 11,
        "text-max-width": 9,
        "icon-padding": ["interpolate", ["linear"], ["zoom"], 0, 2, 14, 2, 16, 20, 17, 2, 22, 2],
      },
      paint: {
        "text-color": customTheme.subplace_label,
        "text-halo-color": customTheme.subplace_label_halo,
        "text-halo-width": 1.5,
      },
    });
  }

  const ss: StyleSpecification = {
    version: 8,
    glyphs: "https://tiles.shagr.at/basemaps-assets/fonts/{fontstack}/{range}.pbf",
    sources: {
      protomaps: {
        type: "vector",
        maxzoom: maxzoom,
        attribution:
          'Basemap <a href="https://github.com/protomaps/basemaps">Protomaps</a> © <a href="https://openstreetmap.org">OpenStreetMap</a>',
        ...(url.indexOf("pmtiles") > -1
          ? {
              url: "pmtiles://" + url,
            }
          : {
              tiles: [url],
            }),
      },
    },
    layers: layers,
  };

  if (sprite) {
    ss.sprite = `https://tiles.shagr.at/basemaps-assets/sprites/v3/${sprite}`;
  }
  return ss;
};
